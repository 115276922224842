import { SB_TOKEN, BASE_URL} from "./configs";

export const TWITTER_EMBADED_ANCHOR = "https://twitter.com/AkasaAir?ref_src=twsrc%5Etfw";
export const TWITTER_SCRIPT_SRC ="https://platform.twitter.com/widgets.js";

//Story Block content API
export const STORY_BLOK_API ="https://api.storyblok.com/v2/cdn/stories/darksite-content?cv=1659526959&token="+`${SB_TOKEN}`;
export const GUPSHUP_EMAIL_API = `${BASE_URL}`+"api/ibe/support/contactUs";

export const FEEDBACK_MAX_CHARS = 1000;
export const FEEDBACK_MIN_CHARS = 10;

export const DEBOUNCE_DELAY = 500;
export const ANIMATION_DELAY = 3000;
export const ANIMATION_END_DELAY = 5000;