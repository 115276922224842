import { SVGProps } from "react";

const SvgComponent = (props) => (
  <svg
  style={{marginRight:"12px"}}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.43602 6.1652L2.66602 7.94521L12.566 17.8352L22.466 7.9352L20.696 6.1652L12.566 14.2952L4.43602 6.1652Z"
      fill="#FF6300"
    />
  </svg>
);

export default SvgComponent;
