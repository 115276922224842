import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import theme from "../constants/themes";

const CarouselWithCenterNavigation = ({ props }) => {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "-60px",
    width: 30,
    height: 30,
    cursor: "pointer",
    margin: "0px 2%",
    color: theme.lightMode.primaryWhite,
  };

  const indicatorStyles = {
    background: theme.lightMode.primaryGray,
    width: 38,
    height: 4,
    margin: "10px 8px 0px 8px",
    borderRadius: "5px",
    position: "relative",
    bottom: "-10px",
    opacity: "0.3",
    display: "inline-block",
  };

  return (
    <div className="h-auto relative CarouselWithCenterNavigation grab-offers">
      <Carousel
        showThumbs={false}
        showStatus={false}
        transitionTime={1500}
        interval={5000}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
                color: theme.lightMode.primaryOrange,
                // left: `${blok.arrowPosition === "topRight" ? "" : "15px"}`,
                right: "50px",
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: "inherit" }} />
            </button>
          )
        }
        style={{ paddingBottom: "50px", overflow: "visible" }}
        autoPlay={true}
        infiniteLoop={true}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "inherit" }} />
            </button>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{
                  ...indicatorStyles,
                  opacity: "1",
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {Array.isArray(props)
          ? props.map((ele, idx) => {
              return (
                // Wrapper Container
                <div className="newsCardsWrapper">
                  {Array.isArray(ele.body)
                    ? ele.body.map((item, ind) => {
                        return (
                          <div className="newsCard">
                            {/* NewsContent  */}
                            <p className="newsCardHeading">
                              {item?.body[0].body}
                            </p>
                            <p className="newsTimeStamp">{item?.body[1].body}</p>
                            <div>
                              <style>
                                {`
                                  .rich-editor li{
                                      white-space: unset !important;
                                  }
                                  .rich-editor ul{
                                      list-style: disc;
                                  }
                              `}
                              </style>

                              <div
                                className="rich-editor"
                                dangerouslySetInnerHTML={{
                                  __html: item?.body[2].body,
                                }}
                              />
                            </div>

                            {/* Read more Url */}
                            <p className="readMoreTag">
                              <a href={item.body[3].links?.url} target="_blank">
                                {item.body[3].displayName}
                              </a>
                            </p>
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })
          : null}
      </Carousel>
    </div>
  );
};

export default CarouselWithCenterNavigation;
