import React from "react";
import "./header.css";
// import Logo from './Akasa_Logo.svg';

const Header = ({ blok }) => {
  const NavBarContent = blok?.body;
  return (
    <>
      <div className="headerContainer">
        <div className="navWrapper">
          <div className="logoWrapper">
            <a
              href={
                Array.isArray(NavBarContent)
                  ? NavBarContent[0].navbarLogoLink?.url
                  : "#"
              }
            >
              <img
                src={
                  Array.isArray(NavBarContent)
                    ? NavBarContent[0].navBarLogo?.filename
                    : null
                }
                alt="Akasa Logo"
              />
            </a>
          </div>
          <div>
            <a
              className="anchorLink"
              href={
                Array.isArray(NavBarContent)
                  ? NavBarContent[1].links?.url
                  : null
              }
            >
              {Array.isArray(NavBarContent) ? NavBarContent[1].displayName : ""}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
