import React from "react";
import "./Banner.css";
const Banner = ({blok}) => {
  const BannerContent = blok?.body;
  return (
    <>
      <div className="bannerCard">
        <p className="newsHeaders">
          {Array.isArray(BannerContent)? BannerContent[0].Body[0].body[0].body : ""}
        </p>
        <a className="knowMore" href={Array.isArray(BannerContent)? BannerContent[0].Body[0].body[1].links?.url : ""}>
        {Array.isArray(BannerContent)? BannerContent[0].Body[0].body[1].displayName : ""}
        </a>
      </div>
    </>
  );
};

export default Banner;
