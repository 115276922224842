// import { Height } from "@mui/icons-material";
import {
  Grid,
  TextField,
  Button,
  TextareaAutosize,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import {
  ANIMATION_DELAY,
  ANIMATION_END_DELAY,
  DEBOUNCE_DELAY,
  FEEDBACK_MAX_CHARS,
  FEEDBACK_MIN_CHARS,
} from "../../constants";
import {
  sendEmail,
  validateEmail,
  validateUserName,
} from "../../constants/functions";
import theme from "../../constants/themes";
import TwitterContainer from "../TwitterTimeline";
import "./contactUS.css";

const ContactUs = ({ blok }) => {
  const ContactUsContent = blok?.body;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    remarks: "",
    response_required: false,
  });

  const [timerId, setTimerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [remarksError, setRemarksError] = useState(false);
  const [validRemark, setValidRemark] = useState(false);

  const main = (e) => {
    const Entry = e.target.name;

    if (Entry === "name") {
      if (validateUserName(e.target.value)) {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }

    if (Entry === "email") {
      if (validateEmail(e.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }

    if (Entry === "mobile") {
      if (e.target.value.length > 10 || e.target.value.length < 10) {
        setMobileError(true);
      } else {
        setMobileError(false);
      }
    }
    if (Entry === "remarks") {
      if (
        e.target.value.length > FEEDBACK_MAX_CHARS ||
        e.target.value.length < FEEDBACK_MIN_CHARS
      ) {
        setRemarksError(true);
      } else {
        setRemarksError(false);
      }
    }

    // setIsDisabled(false);
  };

  /**
   * triggers the main function after the specified delay
   * @param e - event
   * @param delay - debounce delay
   */
  const debounce = (e, delay) => {
    // setIsDisabled(true);

    if (e.target.name === "name") {
      setNameError(false);
    }
    if (e.target.name === "email") {
      setEmailError(false);

      if (!e.target.name) {
        setEmailError(false);
      }

      if (e.target.name === "mobile") {
        setMobileError(false);
      }
    }

    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }

    setTimerId(
      setTimeout(function () {
        main(e);
      }, delay)
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    debounce(e, DEBOUNCE_DELAY);
  };

  // Checkbox Function
  const handleCheckBoxChange = (event) => {
    setFormData({
      ...formData,
      response_required: event.target.checked,
    });
  };

  // Submit Function
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("formData +>", formData);

    setIsLoading(true);
    let res = sendEmail(formData);
    setTimeout(function () {
      setIsLoading(false);
    }, ANIMATION_DELAY);

    setFormData({
      name: "",
      email: "",
      mobile: "",
      remarks: "",
      response_required: false,
    });
  };

  // return (
  //   <>
  //     <div className="container">
  //       <div className="innerCard">
  //         <Grid className="socialMedia" container spacing={2}>
  //           {/* Social media Updates */}
  //           <Grid item md={6} xs={12}>
  //             <div className="scrollCard">
  //               <div>
  //                 <div className="socialMediaHeader">
  //                   {/* Social media Updates */}
  //                   <h2>
  //                     {Array.isArray(ContactUsContent)
  //                       ? ContactUsContent[0]?.Body[3]?.body[11]?.body
  //                       : null}
  //                   </h2>
  //                 </div>

  //                 {/* TwitterTimeline  */}
  //                 <TwitterContainer
  //                   Tweetcount={
  //                     Array.isArray(ContactUsContent)
  //                       ? ContactUsContent[0]?.tweetsCountPerWidget
  //                       : 4
  //                   }
  //                 />
  //               </div>
  //             </div>
  //           </Grid>

  //           {isLoading ? (
  //             // loading Icon
  //             <Grid item md={6} xs={12}>
  //               <div className="loaderContainer">
  //                 {/* Contact Us  */}
  //                 <h2 className="header">
  //                   {Array.isArray(ContactUsContent)
  //                     ? ContactUsContent[0]?.Body[3]?.body[0]?.body
  //                     : null}
  //                 </h2>

  //                 <Grid container>
  //                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
  //                     <div className="contactInfo">
  //                       {/* Corporate Office */}
  //                       <h4>
  //                         {Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[3]?.body[1]?.description
  //                           : null}
  //                       </h4>
  //                       {/* Content  */}
  //                       <p>
  //                         {Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[3]?.body[1].body
  //                           : null}
  //                       </p>
  //                     </div>
  //                   </Grid>
  //                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
  //                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  //                       <div className="contactInfo">
  //                         {/* Call us */}
  //                         <h4>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[2]
  //                                 ?.description
  //                             : null}
  //                         </h4>
  //                         {/* Contact */}
  //                         <p>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[2]?.body
  //                             : null}
  //                         </p>
  //                       </div>
  //                       <div className="contactInfo">
  //                         {/* Email us */}
  //                         <h4>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[3]
  //                                 ?.description
  //                             : null}
  //                         </h4>
  //                         {/* EmailId */}
  //                         <p>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[3]?.body
  //                             : null}
  //                         </p>
  //                       </div>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //                 <div className="loaderWrapper">
  //                   <img
  //                     src={
  //                       Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[3]?.body[10]?.image
  //                             ?.filename
  //                         : "/akasa_preloader.gif"
  //                     }
  //                     alt="Loader"
  //                   />
  //                 </div>
  //               </div>
  //             </Grid>
  //           ) : (
  //             /* Contact US Form */
  //             <Grid item md={6} xs={12}>
  //               <div>
  //                 {/* Contact Us  */}
  //                 <h2>
  //                   {Array.isArray(ContactUsContent)
  //                     ? ContactUsContent[0]?.Body[3]?.body[0].body
  //                     : null}
  //                 </h2>

  //                 <Grid cals container spacing={2}>
  //                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
  //                     <div className="contactInfo">
  //                       {/* Corporate Office */}
  //                       <h4>
  //                         {Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[3]?.body[1].description
  //                           : null}
  //                       </h4>
  //                       {/* Content  */}
  //                       <p>
  //                         {Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[3]?.body[1].body
  //                           : null}
  //                       </p>
  //                     </div>
  //                   </Grid>
  //                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
  //                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  //                       <div className="contactInfo">
  //                         {/* Call us */}
  //                         <h4>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[2]
  //                                 .description
  //                             : null}
  //                         </h4>
  //                         {/* Contact */}
  //                         <p>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[2].body
  //                             : null}
  //                         </p>
  //                       </div>
  //                       <div className="contactInfo">
  //                         {/* Email us */}
  //                         <h4>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[3]
  //                                 .description
  //                             : null}
  //                         </h4>
  //                         {/* EmailId */}
  //                         <p>
  //                           {Array.isArray(ContactUsContent)
  //                             ? ContactUsContent[0]?.Body[3]?.body[3].body
  //                             : null}
  //                         </p>
  //                       </div>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </div>

  //               {/*Form Section */}
  //               <div>
  //                 {/* Write to US  */}
  //                 <h4>
  //                   {Array.isArray(ContactUsContent)
  //                     ? ContactUsContent[0]?.Body[3]?.body[4].body
  //                     : null}
  //                 </h4>
  //               </div>

  //               <Grid container spacing={2}>
  //                 {/* Name */}
  //                 <Grid className="formFields" item xs={12} sm={4} md={4} lg={4} xl={4}>
  //                   <TextField
  //                     value={formData.name}
  //                     onChange={handleChange}
  //                     fullWidth
  //                     autoComplete="off"
  //                     label={
  //                       Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[3]?.body[5].body
  //                         : null
  //                     }
  //                     inputProps={{ style: { textTransform: "capitalize" } }}
  //                     name="name"
  //                     error={nameError}
  //                     helperText={
  //                       nameError
  //                         ? Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[6]?.body[0].body
  //                           : null
  //                         : ""
  //                     }
  //                     sx={{
  //                       maxHeight: "48px",
  //                       ".MuiOutlinedInput-root": {
  //                         borderRadius: "8px 0px 8px 8px",
  //                         height: "48px",
  //                         background: theme.lightMode.primaryWhite,
  //                       },
  //                     }}
  //                   />
  //                 </Grid>

  //                 {/* EmailID */}
  //                 <Grid className="formFields" item xs={12} sm={4} md={4} lg={4} xl={4}>
  //                   <TextField
  //                     value={formData.email}
  //                     onChange={handleChange}
  //                     fullWidth
  //                     autoComplete="off"
  //                     label={
  //                       Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[3]?.body[6].body
  //                         : null
  //                     }
  //                     name="email"
  //                     error={emailError}
  //                     helperText={
  //                       emailError
  //                         ? Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[6]?.body[1].body
  //                           : null
  //                         : ""
  //                     }
  //                     sx={{
  //                       maxHeight: "48px",
  //                       ".MuiOutlinedInput-root": {
  //                         borderRadius: "8px 0px 8px 8px",
  //                         height: "48px",
  //                         background: theme.lightMode.primaryWhite,
  //                       },
  //                     }}
  //                   />
  //                 </Grid>

  //                 {/* Mobile */}
  //                 <Grid className="formFields" item xs={12} sm={4} md={4} lg={4} xl={4}>
  //                   <TextField
  //                     value={formData.mobile}
  //                     onChange={handleChange}
  //                     fullWidth
  //                     label={
  //                       Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[3]?.body[7].body
  //                         : null
  //                     }
  //                     type="number"
  //                     name="mobile"
  //                     error={mobileError}
  //                     helperText={
  //                       mobileError
  //                         ? Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[6]?.body[2].body
  //                           : null
  //                         : ""
  //                     }
  //                     sx={{
  //                       maxHeight: "48px",
  //                       ".MuiOutlinedInput-root": {
  //                         borderRadius: "8px 0px 8px 8px",
  //                         height: "48px",
  //                         background: theme.lightMode.primaryWhite,
  //                       },
  //                     }}
  //                   />
  //                 </Grid>

  //                 {/* Remarks */}
  //                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  //                   <TextareaAutosize
  //                     aria-label="minimum height"
  //                     minRows={3}
  //                     placeholder={
  //                       Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[3]?.body[8].body
  //                         : null
  //                     }
  //                     value={formData.remarks}
  //                     onChange={handleChange}
  //                     name="remarks"
  //                     className="textFields"
  //                   />
  //                   <p className="remarkErrorText">
  //                     {remarksError
  //                       ? Array.isArray(ContactUsContent)
  //                         ? ContactUsContent[0]?.Body[6]?.body[3].body
  //                         : null
  //                       : ""}
  //                   </p>
  //                   <div className="remarksErrorContainer">
  //                     <div className="checkBoxWrapper">
  //                       <Checkbox
  //                         name="response_required"
  //                         checked={Boolean(formData.response_required)}
  //                         onChange={handleCheckBoxChange}
  //                         inputProps={{ "aria-label": "controlled" }}
  //                         sx={{
  //                           color: theme.lightMode.primaryOrange,
  //                           mt:0, 
  //                           "&.Mui-checked": {
  //                             color: theme.lightMode.primaryOrange,
  //                           },
  //                         }}
  //                       />
  //                       <label className="checkBoxLabel">
  //                         {Array.isArray(ContactUsContent)
  //                           ? ContactUsContent[0]?.Body[3]?.body[12]?.body
  //                           : null}
  //                       </label>
  //                     </div>

  //                     <p className="text-right">
  //                       {formData.remarks.length}/{FEEDBACK_MAX_CHARS}
  //                     </p>
  //                   </div>
  //                 </Grid>

  //                 {/* EmptyGrids  */}
  //                 <Grid
  //                   className="emptyGrids"
  //                   item
  //                   xs={12}
  //                   sm={4}
  //                   md={4}
  //                   lg={4}
  //                   xl={4}
  //                 ></Grid>
  //                 <Grid
  //                   className="emptyGrids"
  //                   item
  //                   xs={12}
  //                   sm={4}
  //                   md={4}
  //                   lg={4}
  //                   xl={4}
  //                 ></Grid>

  //                 {/* SubmitButton  */}
  //                 <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
  //                   <Button
  //                     sx={{
  //                       background: theme.lightMode.primaryOrange,
  //                       borderRadius: "8px 0px 8px 8px",
  //                       fontWeight: "600",
  //                       padding: "8px 40px",
  //                       fontSize: "16px",
  //                       "&:hover": {
  //                         background: theme.lightMode.primaryOrange,
  //                       },
  //                     }}
  //                     fullWidth
  //                     onClick={handleSubmit}
  //                     variant="contained"
  //                     disabled={
  //                       nameError ||
  //                       emailError ||
  //                       mobileError ||
  //                       remarksError ||
  //                       !formData.name ||
  //                       !formData.email ||
  //                       !formData.mobile ||
  //                       !formData.remarks
  //                     }
  //                   >
  //                     {Array.isArray(ContactUsContent)
  //                       ? ContactUsContent[0]?.Body[3]?.body[9].body
  //                       : null}
  //                   </Button>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           )}
  //         </Grid>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default ContactUs;
