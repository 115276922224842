import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import DownArrow from "../../DownArrow";
import "./updatesCard.css";

const Updates = ({ blok }) => {
  const RecentUpdateContent = blok?.body;
  const EmergencyContact = blok?.body;

  // FromData
  const [formData, setFromData] = useState({
    country: "",
    state: "",
  });

  const handleChange = (e) => {
    setFromData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Submit Function
  const handleSubmit = (e) => {
    e.preventDefault();
    setFromData({
      country: "",
      state: "",
    });
  };

  return (
    <>
      {/*Recent Updates Container */}
      <div className="updateContainer">
        <div className="gridContainer">
          {/* <div className="imageCard">
            {Array.isArray(RecentUpdateContent) ? (
              RecentUpdateContent[0]?.Body?.[1]?.body?.[0]?.image ? (
                <img
                  src={
                    Array.isArray(RecentUpdateContent)
                      ? RecentUpdateContent[0]?.Body?.[1]?.body?.[0]?.image?.filename
                      : ""
                  }
                  alt="Image"
                />
              ) : null
            ) : null}
          </div> */}
          <div className="UpdateContent">
            {/* Heading */}
            <h2 className="updateHeader">
              {Array.isArray(RecentUpdateContent)
                ? RecentUpdateContent[0].Body[1].body[1].body
                : ""}
            </h2>
            {/*Date*/}
            <p className="timeAndDate">
              {Array.isArray(RecentUpdateContent)
                ? RecentUpdateContent[0].Body[1].body[2].body
                : ""}
            </p>
            {/* Content */}
            <div>
              <style>
                {`
                    .rich-editor li{
                        white-space: unset !important;
                    }
                    .rich-editor ul{
                        list-style: disc;
                    }
                `}
              </style>
              <div
                className="rich-editor"
                dangerouslySetInnerHTML={{
                  __html: Array.isArray(RecentUpdateContent)
                    ? RecentUpdateContent[0].Body[1].body[3].content?.content
                    : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Emergency Contacts Container */}
      <div className="whiteBg">
        <div className="grid">
          <div className="emergencyContent">
            {/* Title */}
            <h2 className="updateHeader">
              {Array.isArray(EmergencyContact)
                ? EmergencyContact[0]?.Body?.[2]?.body?.[0]?.body
                : ""}
            </h2>
            {/* <Grid container spacing={2}> */}
            {/* Countries DropDown */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl
                  fullWidth
                  sx={{
                    margin: "4px 0px 0px 0px",
                    borderRadius: "8px 0px 8px 8px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    sx={{
                      borderRadius: "8px 0px 8px 8px",
                      height: "48px",
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          marginTop: "12px",
                        },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.country}
                    onChange={handleChange}
                    label="Country"
                    name="country"
                    IconComponent={DownArrow}
                  >
                    {Array.isArray(EmergencyContact)
                      ? EmergencyContact[0]?.Body?.[2]?.body?.[1]?.body.map(
                          (ele, ind) => {
                            return (
                              <MenuItem
                                key={ind}
                                value={ele.Name}
                                className="sign-up-dropdown"
                              >
                                {ele.Name}
                              </MenuItem>
                            );
                          }
                        )
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}

            {/* States DropDwon */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl
                  fullWidth
                  sx={{
                    margin: "4px 0px 0px 0px",
                    borderRadius: "8px 0px 8px 8px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    sx={{
                      borderRadius: "8px 0px 8px 8px",
                      height: "48px",
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          marginTop: "12px",
                        },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.state}
                    onChange={handleChange}
                    label="State"
                    name="state"
                    IconComponent={DownArrow}
                    disabled={!formData.country}
                  >
                    {Array.isArray(EmergencyContact)
                      ? EmergencyContact[0]?.Body[2]?.body[1]?.body.map(
                          (ele, ind) => {
                            if (ele.Name == formData.country) {
                              return ele?.body?.map((state, idx) => {
                                return (
                                  <MenuItem
                                    key={ind}
                                    value={state.Name}
                                    className="sign-up-dropdown"
                                  >
                                    {state.Name}
                                  </MenuItem>
                                );
                              });
                            }
                          }
                        )
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}

            {/* contact Details Sections */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="">
                  <div className="contactCity">
                    {Array.isArray(EmergencyContact)
                      ? EmergencyContact[0]?.Body[2]?.body[1]?.body.map(
                          (ele, ind) => {
                            if (ele.Name == formData.country) {
                              return ele?.body?.map((state, idx) => {
                                if (state.Name === formData.state) {
                                  return state.body.map((ele, idx) => {
                                    return (
                                      <>
                                        <div>
                                          <h4>{ele.Name}</h4>
                                          {ele?.body.map((contact, idx) => {
                                            return (
                                              <>
                                                <p>{contact.body}</p>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </>
                                    );
                                  });
                                }
                              });
                            }
                          }
                        )
                      : null}
                  </div>
                </div>
              </Grid> */}
            {/* </Grid> */}

            {/* description */}
            <div>
              <div className="contactDetailsCard">
                {Array.isArray(EmergencyContact)
                  ? EmergencyContact[0]?.Body?.[2]?.body?.[2]?.body?.map(
                      (ele, idx) => {
                        return (
                          <div>
                            <h4>{ele?.Name}</h4>
                            {ele?.body.map((item, ind) => {
                              return <p>{item?.body}</p>;
                            })}
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </div>

          {/* image Card */}
          <div className="ContactCard">
            {Array.isArray(RecentUpdateContent) ? (
              RecentUpdateContent[0]?.Body?.[2]?.body?.[3]?.image ? (
                <img
                  src={
                    Array.isArray(RecentUpdateContent)
                      ? RecentUpdateContent[0]?.Body?.[2]?.body?.[3]?.image
                          ?.filename
                      : null
                  }
                  alt="Image"
                />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Updates;
