import { Divider } from "@mui/material";
import React from "react";
import "./Footer.css";

const FooterBanner = ({ blok }) => {
  const FooterContent = blok?.body;

  return (
    <>
      <div className="footerBanner">
        <div>
          {/* Footer Logo*/}
          <a
            href={
              Array.isArray(FooterContent)
                ? FooterContent[0].navbarLogoLink?.url
                : "#"
            }
          >
            <img
              src={
                Array.isArray(FooterContent)
                  ? FooterContent[0]?.footerLogo?.filename
                  : ""
              }
              alt="Footer Logo"
            />
          </a>
        </div>
        {/* CopyRight Disclaimer*/}
        <div>
          <span className="copyRightsSection">
            {Array.isArray(FooterContent)
              ? FooterContent[0]?.Body[5]?.body[0].body
              : ""}
          </span>
        </div>
        <div className="TCSection">
          {/* Terms & Conditions*/}
          <a
            href={
              Array.isArray(FooterContent)
                ? FooterContent[0]?.Body[5]?.body[1]?.links?.cached_url
                : ""
            }
          >
            {Array.isArray(FooterContent)
              ? FooterContent[0]?.Body[5]?.body[1].displayName
              : ""}
          </a>
          <span className="divider"></span>
          {/* Privacy Policy */}
          <a
            href={
              Array.isArray(FooterContent)
                ? FooterContent[0]?.Body[5]?.body[2]?.links?.cached_url
                : ""
            }
          >
            {Array.isArray(FooterContent)
              ? FooterContent[0]?.Body[5]?.body[2].displayName
              : ""}
          </a>
        </div>
      </div>
    </>
  );
};

export default FooterBanner;
