const theme = {
  lightMode: {
    primaryOrange: "#FF6300",
    secondaryOrnage: "#FFEFE5",
    orange50: "#FFDFCC",
    primaryPurple: "#5C0FD9",
    primaryGray: "lightgray",
    secondaryGray: "#666666",
    primaryWhite: "#FFFFFF",
    primaryBlack: "#000",
    lightPurple: "#DECFF6",
    successGreen: "#037E0B",
    errorRed: "#D31313",
    enablePurple: "#eee7fa",
  },
  darkMode: {},
  fontFamily: {
    primary: "Roboto",
  },
};

export default theme;
