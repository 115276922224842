import logo from "./logo.svg";
import "./App.css";
import Banner from "./components/Banner";
import Header from "./components/Header";
import Updates from "./components/Updates";
import ContactUs from "./components/ContactUsForm";
import PressRoomCards from "./components/PressRoom";
import FooterBanner from "./components/FooterBanner";
import { useEffect, useState } from "react";
import { STORY_BLOK_API } from "./constants";

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${STORY_BLOK_API}`)
      .then((res) => {
        return res.json();
      })
      .then(async (Data) => await setData(Data?.story?.content))
      .catch((err) => console.log(err.message));
  });

  return (
    <div className="App">
      <Header blok={data}/>
      <Banner blok={data}/>
      <Updates blok={data}/>
      <ContactUs blok={data}/>
      <PressRoomCards blok={data}/>
      <FooterBanner blok={data}/>
    </div>
  );
}

export default App;
