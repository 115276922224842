import React from "react";
import CarouselWithCenterNavigation from "../carousel";
import "./pressRoom.css";

const PressRoomCards = ({ blok }) => {
  const NewsContent = blok?.body;

  return (
    <>
      <div className="pressRoomContainer">
        <div className="cardsContainer">
          {/* Press Room  */}
          <h2>
            {Array.isArray(NewsContent) ? NewsContent[0]?.Body[4]?.Name : null}
          </h2>

          {/*Carousel Cards Wrapper  */}
          <div className="cardWrapper">
            <CarouselWithCenterNavigation
              props={
                Array.isArray(NewsContent)
                  ? NewsContent[0]?.Body[4]?.body
                  : null
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PressRoomCards;
